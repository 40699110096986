<template>
  <sm-editable-list
    :controllerName="controllerName"
    :breadcrumbs="breadcrumbs"
    :tableCaption="tableCaption"
    :tableHeaders="tableHeaders"
    showCreateButton
    showEditButton
    showDeleteButton
    @create="onCreate('DatabaseWithInsurancesCreate')"
    @edit="(id) => onEdit('DatabaseWithInsurancesEdit', id)"
  />
</template>

<script>
// mixins
import editableList from '@/mixins/editableList.js';
// components
import SmEditableList from '@/components/views/SmEditableList.vue';

export default {
  name: 'DatabasesWithInsurances',

  components: {
    SmEditableList,
  },

  mixins: [editableList],

  data() {
    return {
      controllerName: 'DatabasesWithInsurances',
      tableCaption: 'Базы данных со страхованием',
      breadcrumbs: [
        {
          text: 'Мониторинг',
          route: { name: 'MonitoringMain' },
        },
        {
          text: 'Базы данных со страхованием',
        },
      ],
      tableHeaders: [
        { text: 'Имя базы данных', alias: 'dbName', order: 'dbName' },
        { text: '', alias: 'actions', order: 'actions' },
      ],
    };
  },
};
</script>
